import { useEffect, useRef, useState } from 'react';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createIncidents, createIncidentsVO, getVentaInfo, getVentaInfoVO } from '../shared/services/incidencias';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import { toast } from 'react-toastify';
import Spinner from '../../Main/shared/components/Spinner';
import Step0 from './Step0';

export default function CreateIncidents ({ comesFrom }) {
    const { t, i18n } = useTranslation();
    const recaptcha = useRef();
    const [searchParams] = useSearchParams();
    const { ventaId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [venta, setVenta] = useState(null);
    const [tipoIncidencias, setTipoIncidencias] = useState([]);
    const [step, setStep] = useState(1);
    const [errorStep1, setErrorStep1] = useState(false);
    const [errorStep2, setErrorStep2] = useState(false);
    const [errorStep3, setErrorStep3] = useState(null);
    const [isShowStep2, setIsShowStep2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            venta_id: ventaId || '',
            tipo_incidencia_id: '',
            piezas: [],
            comentarios_cliente: ''
        }
    });

    useEffect(() => {
        const getInfo = async (comesFrom) => {
            setLoading(true);
            let response;

            if (comesFrom) {
                response = await getVentaInfoVO(ventaId).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            } else {
                response = await getVentaInfo(ventaId).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            }

            if (response && response.success) {
                setLoading(false);
                setTipoIncidencias(response.data.tipoIncidencias);
                setVenta(response.data.venta);
            }
        };

        if (comesFrom === 'qr') {
            const lang = searchParams.get('lang')?.toLowerCase();
            i18n.changeLanguage(lang);
            setStep(0);
        } else {
            setStep(1);

            if (ventaId) {
                getInfo(comesFrom);
            } else toast.error('No hay venta!');
        }
    }, []);

    const handleSetPart = (ventaInfo, tipoIncidencias) => {
        setValue('venta_id', ventaInfo.id);
        setVenta(ventaInfo);
        setTipoIncidencias(tipoIncidencias);
        setStep(1);
    };

    const handleChangeStep = (newStep) => {
        if (newStep === 2) {
            if (getValues('tipo_incidencia_id') === '') {
                setErrorStep1(true);
                return;
            } else {
                setErrorStep1(false);

                if (step === 1) {
                    if (venta.piezas.length === 1) {
                        setStep(3);
                        setIsShowStep2(false);
                        return;
                    }
                } else {
                    if (venta.piezas.length === 1) {
                        setStep(1);
                        setIsShowStep2(false);
                        return;
                    }
                }
                setIsShowStep2(true);
            }
        } else if (newStep === 3) {
            if (getValues('piezas').length === 0) {
                setErrorStep2(true);
                return;
            } else setErrorStep2(false);
        }
        setStep(newStep);
    };

    const handleSubmitIncidence = (data, e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));

        if (data.comentarios_cliente?.length === 0) {
            setErrorStep3({ observaciones: true });
            return;
        } else {
            const fileExtension = ['jpeg', 'jpg', 'png', 'mp4', 'mov'];

            if (data.files?.length > 12) {
                setErrorStep3({ fileLengthMax: true });
                return;
            }

            if (data.files?.length === 0 || data.files?.length < 2) {
                setErrorStep3({ fileLengthMin: true });
                return;
            }

            for (let i = 0; i < data.files?.length; i++) {
                const fileName = data.files[i].name.split('.');
                const ext = fileName[fileName.length - 1]?.toLowerCase();

                if (data.files[i].size > 20971520) {
                    setErrorStep3({ maxFileSize: true });
                    return;
                }

                if (fileExtension.lastIndexOf(ext) === -1) {
                    setErrorStep3({ fileExt: true });
                    return;
                }
            }
        }

        if (venta.piezas.length === 1 && data.piezas.length === 0) {
            data.piezas = [venta.piezas[0].id];
        }
        handleCreateIncident({ ...data, captchaValue });
    };

    const handleCreateIncident = async (data) => {
        setIsLoading(true);
        const dataAux = new FormData();

        for (let i = 0; i < data.files.length; i++) {
            dataAux.append('files', data.files[i]);
        }

        Object.entries(data).forEach((value) => {
            if ((value[0] !== 'files')) {
                dataAux.append(value[0], value[1]);
            }
        });

        let response;

        if (comesFrom) {
            response = await createIncidentsVO(dataAux).catch(function (error) {
                toast.error(setErrorMessage(error));
            });
        } else {
            response = await createIncidents(dataAux).catch(function (error) {
                toast.error(setErrorMessage(error));
            });
        }

        if (response && response.success) {
            toast.success(response.message);
            navigate('/gracias', { state: { from: 'incident' } });
        }
        setIsLoading(false);
    };

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <Header />

            { (loading)
                ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                            <span className="my-5">{ t('common.loading') }</span>
                        </div>
                    </div>
                )
                : (
                    <div className='w-100 container d-flex align-items-center justify-content-center mt-5'>
                        { (step === 0) &&
                           <Step0 onPartChecked={handleSetPart} />
                        }

                        { (step !== 0) &&
                        <form className="form col-12 col-md-8 col-xl-6" onSubmit={handleSubmit(handleSubmitIncidence)}>
                            <input
                                type="hidden"
                                {...register('venta_id')}
                                defaultValue={ventaId} />

                            <div className='w-100 mb-4'>
                                <p className='text-start'>
                                    { t('common.hi') } <span className="fw-bold">{venta?.cliente.nombre}</span>! <br />
                                    { t('crm-views.incident.explanation') }
                                </p>
                            </div>

                            { (venta && venta?.piezas?.length > 1) &&
                                <div className="w-100 alert alert-info mt-2">
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
                                        <small className="ms-2 mb-0">
                                            <b>{ t('common.note') }</b>:
                                            { t('crm-views.incident.note1') }
                                        </small>
                                    </div>
                                </div>
                            }

                            { (step === 1) &&
                                <Step1
                                    tipoIncidencias={tipoIncidencias}
                                    register={register}
                                    errors={errorStep1}
                                />
                            }

                            { (step === 2) && <Step2
                                piezas={venta.piezas}
                                register={register}
                                errors={errorStep2}
                                isShow={isShowStep2}
                            />}

                            { (step === 3) &&
                                <>
                                    <Step3
                                        register={register}
                                        errors={errorStep3}
                                    />

                                    <div className='w-100 d-flex align-items-center justify-content-center'>
                                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                    </div>
                                </>
                            }

                            <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                                { step > 1 && <button type="button" className="btn btn-secondary me-md-2 order-1 order-md-0" onClick={() => handleChangeStep(step - 1)}>{ t('common.back') }</button> }
                                { step < 3 && <button type="button" className="btn btn-primary order-0 order-md-1" onClick={() => handleChangeStep(step + 1)}>{ t('common.next') }</button> }
                                { step === 3 && <button type="submit" className="btn btn-primary order-0 order-md-1 create-incident-submit-btn">{ isLoading ? <Spinner /> : t('common.send') }</button> }
                            </div>
                        </form>
                        }
                    </div>
                )}

            <Footer />
        </div>
    );
}
